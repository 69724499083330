import React from "react";
import Seo from "components/Seo";
import { StaticImage } from "gatsby-plugin-image";
import { VStack, Box, Text } from "@chakra-ui/react";
import Container from "components/ui/Container";

const NotFoundPage = () => (
  <Box py={10}>
    <Seo title="404: Not found" />

    <Container fluid>
      <VStack spacing={10} alignItems="center" textAlign="center">
        <StaticImage src="../images/banners/page-not-found.png" alt="" />

        <Text as="h1" fontSize="5xl" fontWeight="light">
          Page not found
        </Text>
      </VStack>
    </Container>
  </Box>
);

export default NotFoundPage;
